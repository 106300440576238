import React from 'react';
import {Link} from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiInstagram, FiLinkedin, FiGithub } from "react-icons/fi";
import { usePageTranslations } from '../../utils/translation';
import { useTranslation } from 'react-i18next';

const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);

const translations = {
    en: {
        title: 'Stay With Us.',
        subtitle: 'Follow us for the latest updates.',
        companyName: 'Alpaon America LLC',
        ceo: 'CEO : Seongyun Ku',
        privacyOfficer: 'Data Protection Officer : Jisub Kim',
        phone: 'Office : +1-212-380-1711',
        businessNumber: 'Business Registration Number : 796-81-02433',
        ecommerceNumber: 'E-Commerce Registration Number : 2024-인천연수구-2566',
        address: 'Address : 1309 Coffeen Avenue, STE 1200, Sheridan, Wyoming, United States'
    },
    ko: {
        title: 'Stay With Us.',
        subtitle: '최신 소식을 팔로우하세요.', 
        companyName: '알파온 유한책임회사',
        ceo: '대표이사 : 구성윤',
        privacyOfficer: '개인정보보호책임자 : 김지섭',
        phone: '대표번호 : 1661-1711',
        businessNumber: '사업자등록번호 : 796-81-02433',
        ecommerceNumber: '통신판매업신고번호 : 2024-인천연수구-2566',
        address: '주소 : 인천광역시 연수구 송도미래로 30 E동 802호'
    },
};


const FooterOne = () => {
    const { t, nt } = usePageTranslations(translations);
    const { i18n } = useTranslation();
    const lang = i18n.resolvedLanguage;
    return (
        <>
            <footer className="rn-footer footer-style-default">
                <div className="footer-top" style={{ paddingTop: '80px', paddingBottom: '50px'}}>
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="logo">
                                        <Logo 
                                            image={`${process.env.PUBLIC_URL}/images/logo/logo-white.svg`}
                                            image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.svg`}
                                        />
                                    </div>
                                    <h3 className="text-big">{footerIntem.title}</h3>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemOne.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexOneLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemTwo.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexTwoLink.map((data, index) => {
                                                    // 외부 링크인지 확인하기 위한 조건
                                                    const isExternal = data.url.startsWith('http://') || data.url.startsWith('https://');
                                                    
                                                    return (
                                                        <li key={index}>
                                                            {isExternal ? (
                                                                // 외부 링크인 경우 <a> 태그를 사용하고, target="_blank"로 새 탭에서 열기
                                                                <a href={data.url} target="_blank" rel="noopener noreferrer">{data.text}</a>
                                                            ) : (
                                                                // 내부 링크인 경우 Link 컴포넌트 사용
                                                                <Link to={data.url}>{data.text}</Link>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{nt('title')}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{nt('subtitle')}</h6>
                                        <ul className="social-icon social-default justify-content-start">
                                            <li>
                                                <a href="https://www.linkedin.com/company/alpaon" target="_blank" rel="noopener noreferrer">
                                                    <FiLinkedin />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://github.com/alpaon" target="_blank" rel="noopener noreferrer">
                                                    <FiGithub />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/alpaon.official/" target="_blank" rel="noopener noreferrer">
                                                    <FiFacebook />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/alpaon.official/" target="_blank" rel="noopener noreferrer">
                                                    <FiInstagram />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            <div className="copyright-area copyright-style-one" style={{ borderTop: 'none', padding: '0px', paddingTop: '20px'}}>
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="copyright-left">
                                            <h8 className="subtitle" style={{ fontSize: '14px' }}>
                                                {nt('companyName')}<span style={{ margin: '0 10px' }}>|</span>
                                                {nt('ceo')}<span style={{ margin: '0 10px' }}>|</span>
                                                {lang == 'ko' && (
                                                <>
                                                    {nt('privacyOfficer')}
                                                    <span style={{ margin: '0 10px' }}>|</span>
                                                </>
                                                )}
                                                {nt('phone')}
                                                {lang == 'ko' && (
                                                <>
                                                    <br />
                                                    {nt('businessNumber')}
                                                    <span style={{ margin: '0 10px' }}>|</span>
                                                </>
                                                )}
                                                {lang == 'ko' && (
                                                <>
                                                    {nt('ecommerceNumber')}
                                                </>
                                                )}
                                                <span style={{ margin: '0 10px' }}>|</span>
                                                {nt('address')}
                                            </h8>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterOne
